//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTemplate from './SectionTemplate.vue'
export default {
  extends: SectionTemplate,
  data() {
    return {
      provinces_result: [],
      provinces: [],
      cities: [],
      city: '',
      province: '',
      branches: [],
      showloader:'',
      no_branches:''
    }
  },
  created() {
    if (process.browser) {
      this.getProvinces()
    }
  },
  computed: {
    provinceData() {
      const self = this
      if (this.provinces.length > 0) {
        return this.provinces.filter(function (a) {
          return a.name === self.province
        })[0]
      }
      return null
    }
  },
  methods: {
    async getProvinces() {
      try {
      const formdata = {
        "debug" : 1,
      };

      // Local
      // const response = await this.$axios.$post('/USSC_WEBSITE/web-mailer/branch_list.php',
      // Dev
      // const response = await this.$axios.$post('/cmai-poc/ussc-website-web-mailer/branch_list.php',
      // Prod
      const response = await this.$axios.$post('/contact-us/branch_list.php',
      JSON.stringify(formdata),
        {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'api-key' : 'cf83e111112227eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e'
          }
        }
        );

        if(response){
          if(response.status === 1){
            let response_res = response.results;
            this.provinces_result = response.results;
            Object.entries(response_res).forEach(([key, value]) =>
              this.provinces.push(key)
            );
          }else if(response.status === 0){
            
          }
        }
        return;
      } catch (error) {
        console.log(error.response);
      }
      
    },

    updateCities() {
      this.cities = this.provinces_result[this.province];
    },


    async submitData(){

    this.showloader = true

    try {
        this.showloader = true;

        const formdata = {
          "debug" : 1,
          "province" : this.province,
          "city" : this.city
        };

        // Local
        // const response = await this.$axios.$post('/USSC_WEBSITE/web-mailer/branch_details.php',
        // Dev
        // const response = await this.$axios.$post('/cmai-poc/ussc-website-web-mailer/branch_details.php',
        //  Prod
         const response = await this.$axios.$post('/contact-us/branch_details.php',
         JSON.stringify(formdata),
         {
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'api-key' : 'cf83e111112227eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e'
           }
         }
         )
         .then((response) => {
            this.showloader = false
            if(response.status === 1){
              if(response.results){
                this.no_branches = '';
                this.branches = response.results;
                return;
              }
            }else{
              this.branches = [];
              this.no_branches = response.message ? response.message : 'NO RECORD FOUND';
              return;
            }
         })
         .catch((error) => {
          this.showloader = false
          let error_message = 'Error! Please try again!';
            // if(error.response){
            //   if(error.response.data){
            //     error_message = error.response.data.message;
            //   }else{
            //     error_message = error.response.data.message;
            //   }
            // }
            this.showloader = false;
            console.log(error);
           alert(error_message);
         });
     } catch (error) {
        this.showloader = false;
        console.log(error);
     }
    },
  }
}
